/**
 * @generated SignedSource<<2ab0bc565921fe9be96fa1a837068639>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

var node = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "auditProgrammeId",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "databaseId",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "days",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "numberOfDays",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "userIds",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "startTime",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "userId",
  "storageKey": null
},
v11 = [
  (v3/*: any*/),
  (v5/*: any*/)
],
v12 = [
  {
    "kind": "Literal",
    "name": "roles",
    "value": [
      "admin",
      "editor"
    ]
  },
  {
    "kind": "Literal",
    "name": "sortBy",
    "value": "name"
  },
  {
    "kind": "Literal",
    "name": "sortDirection",
    "value": "asc"
  },
  {
    "kind": "Literal",
    "name": "statuses",
    "value": [
      "active",
      "pending"
    ]
  }
],
v13 = [
  (v3/*: any*/),
  (v5/*: any*/),
  (v7/*: any*/)
],
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "number",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "AuditAgendaQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Audit",
        "kind": "LinkedField",
        "name": "audit",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          (v6/*: any*/),
          (v7/*: any*/),
          (v8/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "AuditInterview",
            "kind": "LinkedField",
            "name": "auditInterviews",
            "plural": true,
            "selections": [
              (v3/*: any*/),
              (v7/*: any*/),
              (v9/*: any*/),
              (v10/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "AuditProgramme",
            "kind": "LinkedField",
            "name": "auditProgramme",
            "plural": false,
            "selections": [
              (v5/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "users",
            "plural": true,
            "selections": (v11/*: any*/),
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v12/*: any*/),
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "users",
        "plural": true,
        "selections": (v11/*: any*/),
        "storageKey": "users(roles:[\"admin\",\"editor\"],sortBy:\"name\",sortDirection:\"asc\",statuses:[\"active\",\"pending\"])"
      },
      {
        "args": null,
        "kind": "FragmentSpread",
        "name": "AuditAgendaDay_query"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "AuditAgendaQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Audit",
        "kind": "LinkedField",
        "name": "audit",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          (v6/*: any*/),
          (v7/*: any*/),
          (v8/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "AuditInterview",
            "kind": "LinkedField",
            "name": "auditInterviews",
            "plural": true,
            "selections": [
              (v3/*: any*/),
              (v7/*: any*/),
              (v9/*: any*/),
              (v10/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "day",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "jobTitleId",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "mandatory",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "endTime",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "remarks",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "JobTitle",
                "kind": "LinkedField",
                "name": "jobTitle",
                "plural": false,
                "selections": (v13/*: any*/),
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "auditInterviewProcessesCount",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "AuditInterviewProcess",
                "kind": "LinkedField",
                "name": "auditInterviewProcesses",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ManualParagraph",
                    "kind": "LinkedField",
                    "name": "manualParagraph",
                    "plural": false,
                    "selections": [
                      (v3/*: any*/),
                      (v5/*: any*/),
                      (v14/*: any*/),
                      (v7/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v7/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "AuditProgramme",
            "kind": "LinkedField",
            "name": "auditProgramme",
            "plural": false,
            "selections": [
              (v5/*: any*/),
              (v7/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "users",
            "plural": true,
            "selections": (v13/*: any*/),
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v12/*: any*/),
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "users",
        "plural": true,
        "selections": (v13/*: any*/),
        "storageKey": "users(roles:[\"admin\",\"editor\"],sortBy:\"name\",sortDirection:\"asc\",statuses:[\"active\",\"pending\"])"
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "JobTitleConnection",
        "kind": "LinkedField",
        "name": "jobTitles",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "JobTitle",
            "kind": "LinkedField",
            "name": "nodes",
            "plural": true,
            "selections": [
              (v3/*: any*/),
              (v5/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "ManualParagraph",
                "kind": "LinkedField",
                "name": "manualParagraphs",
                "plural": true,
                "selections": [
                  (v7/*: any*/),
                  (v5/*: any*/),
                  (v14/*: any*/)
                ],
                "storageKey": null
              },
              (v7/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "971d3ca27d7ec720f68a57df3e84e2a4",
    "id": null,
    "metadata": {},
    "name": "AuditAgendaQuery",
    "operationKind": "query",
    "text": "query AuditAgendaQuery(\n  $id: ID!\n) {\n  audit(id: $id) {\n    auditProgrammeId\n    databaseId\n    days\n    name\n    numberOfDays\n    id\n    userIds\n    auditInterviews {\n      databaseId\n      id\n      startTime\n      userId\n    }\n    auditProgramme {\n      name\n      id\n    }\n    users {\n      databaseId\n      name\n      id\n    }\n  }\n  users(roles: [\"admin\", \"editor\"], sortBy: \"name\", sortDirection: \"asc\", statuses: [\"active\", \"pending\"]) {\n    databaseId\n    name\n    id\n  }\n  ...AuditAgendaDay_query\n}\n\nfragment AuditAgendaDay_query on Query {\n  audit(id: $id) {\n    days\n    id\n    numberOfDays\n    auditInterviews {\n      databaseId\n      day\n      id\n      jobTitleId\n      mandatory\n      startTime\n      ...AuditAgendaInterview_auditInterview\n      ...InterviewProcessesTooltip_auditInterview\n    }\n  }\n  ...NewAuditInterviewModal_query\n}\n\nfragment AuditAgendaInterview_auditInterview on AuditInterview {\n  day\n  endTime\n  id\n  mandatory\n  remarks\n  startTime\n  userId\n  jobTitle {\n    databaseId\n    name\n    id\n  }\n}\n\nfragment InterviewProcessesTooltip_auditInterview on AuditInterview {\n  auditInterviewProcessesCount\n  auditInterviewProcesses {\n    manualParagraph {\n      databaseId\n      name\n      number\n      id\n    }\n    id\n  }\n}\n\nfragment NewAuditInterviewModal_query on Query {\n  audit(id: $id) {\n    id\n  }\n  jobTitles {\n    nodes {\n      databaseId\n      name\n      manualParagraphs {\n        id\n        name\n        number\n      }\n      id\n    }\n  }\n}\n"
  }
};
})();

node.hash = "9fba31475446af19b4a46ab864c8490c";

module.exports = node;
